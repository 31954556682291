import React, { Component } from 'react';
import logo from "../../images/logo.png";
import classes from "./TermsAndCondition.module.css"
import Left from "../../images/Left.svg";
import { getQuery } from '../../utils.js';
import { Link } from 'react-router-dom'
class TermsAndCondition extends Component {

    constructor(props) {
        super(props);

    }
    render() {
        const { handleApiCall } = this.props
        return (
            <div className={classes.Header}>
                <div className={classes.Heading}>
                    <Link to={`/?hash=${getQuery()['hash']}`} style={{ width: "10%", display: "flex", alignItems: "center" }} onClick={handleApiCall}>
                        <img src={Left} />
                    </Link>
                    <div style={{ width: "90%" }}>
                        <img src={logo} />
                    </div>
                </div>
                <div className={classes.Content}>

                    <p className={classes.FirstPara}>
                        At Classplus, we believe in growing together. We understand how customers are the strongest pillars
    who can take a company forward. To spread the goodness of technology-powered coaching
    institutes and the experience they are able to provide to their students and parents by saving time
    on daily tasks, we are introducing a referral policy for our customers so that we all grow together.
                                </p>

                    <p className={classes.Question}>
                        Q. What is a referral?</p>
                    <p>A. A referral is when you refer a real coaching institute to our team as a prospect for our services.</p>
                    <p className={classes.Question}>Q. What is a successful referral?</p>
                    <p>A. When the referral you share with the team becomes our customer by subscribing to any of our
                    product packages, it is termed as a successful referral for you.
                                </p>


                    <p className={classes.SecondPara}>
                        We like to share incentives that can help you in your product or commercially whenever you share a
    referral with us, and higher ones when it ends up as a successful referral.
    On every referral, we give you 500 coins, 1000 if they fill the lead form and on every successful
    referral, you get 120000 coins.

    The amount of coins redeemable in one transaction depends on the benefit you are
    availing.
    Every subsequent referral earns you 150000 coins
                                </p>

                    <p className={classes.Question}>
                        Q. How do you redeem coins into benefits ?</p>
                    <p> A. You can redeem your coins for buying free SMS, additional features on your app or at the time of
                     your renewal for discount
                                </p>

                    <p>
                        Please use the table below for understanding the benefits of coins.
                                </p>
                    <table>
                        <tr>
                            <th>
                                Number of coins
                                            </th>
                            <th>
                                What you can get
                                            </th>
                        </tr>
                        <tr>
                            <td>
                                120,000
                                            </td>
                            <td>
                                10,000 SMS
                                            </td>
                        </tr>
                        <tr>
                            <td>
                                350,000
                                            </td>
                            <td>
                                1 year of Video Portal
                                            </td>
                        </tr>
                        <tr>
                            <td>
                                700,000
                                            </td>
                            <td>
                                1 year of Online Test Portal
                                            </td>
                        </tr>
                    </table>
                    <p>
                        You can also redeem your coins for getting a discount on your renewal price. The conversion for
    coins to discount is 10,000 coins = INR 100, upto a maximum of 25% of your renewal amount value.
                                </p>

                </div>

            </div>

        )
    }
}
export default TermsAndCondition