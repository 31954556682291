import Checkbox from '../UI/Checkbox/Checkbox';
import React, { Component } from 'react';
import classes from './Phonelist.module.css';
import { getQuery } from '../../utils.js';
import Loader from '../UI/Loader/Loader';
import AppConfig from '../../AppConfig.js';
import axios from 'axios';
import Modal from '../UI/Modal/Modal';
import FormatModal from '../UI/FormatModal/FormatModal';
import verified from '../../images/verified.svg';
import whatsapp from '../../images/whatsapp.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import search from '../../images/search.svg';
import add from '../../images/add.svg';
import TermsAndCondition from "../TermsAndCondition/TermsAndCondition.js"
import { Link } from 'react-router-dom'
class Phonelist extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			listing: [],
			imageUrl: '',
			payload: [],
			imageKnowMoreLink: '',
			referDisabled: true,
			whatsappUrl: '',
			payloadLength: 0,
			isTutorReferral: 0,
			contactList: [{
				name: "",
				mobile: ""
			}],
			numberLengthExceeded: false,
			nameEmpty: false,
			isReferClicked: false,
			isAddClicked: false,
			hasMore: false,
			limit: 20,
			offset: 0,
			searchTerm: '',
			totalCount: 0,
			addSuccessModal: false,
			coinsEarned: 0,
			payloadType: 1

		};
	}


	componentDidMount() {
		this.handleApiCall();

	}

	handleApiCall = () => {
		this.setState({ loading: true });
		const { searchTerm, offset, limit } = this.state;
		let search = searchTerm ? `&search=${searchTerm}` : '';
		const promoHeaders = {
			'Api-Version': 3,
			'x-access-token': getQuery()['hash'] || "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwib3JnSWQiOjEsIm5hbWUiOiJNYXZlcmljayAiLCJlbWFpbCI6InNhaGlsQGNsYXNzcGx1cy5jb1xuIiwibW9iaWxlIjoiOTE4NzYzMDA2MDAxIiwidHlwZSI6MywiaWF0IjoxNTg0MjE3NTk3LCJleHAiOjE1ODU5NDU1OTd9.IU0bjwNCQy5wX4YrVXKt7bYnBZ6zNn4Rj2GT472aASErAcG8F7oHEkyh6qb4WqMW"
		};

		axios
			.get(`${AppConfig.BASE_URL}/users/get/contactList?limit=${limit}&offset=${offset}${search}`, {
				headers: promoHeaders
			})
			.then((response) => {

				this.setState({
					loading: false,
					imageKnowMoreLink: response.data.data.imageKnowMoreLink,
					imageUrl: "https://clevertapimages.s3.ap-south-1.amazonaws.com/Extra-live-hours-notification+(1).jpg",
					listing: this.state.offset === 0 ? response.data.data.userContactList : [...this.state.listing, ...response.data.data.userContactList],
					hasMore: response.data.data.userContactList.length > 19 ? true : false,
					offset: offset + limit,

					totalCount: response.data.data.count
				});
			})

			.catch((error) => {
				this.setState({ loading: false });
			});
	};



	handleAddClicked = () => {
		this.setState({ isAddClicked: true, numberLengthExceeded: false, nameEmpty: false });

	};

	handleAddToggle = () => {
		this.setState({ isAddClicked: false });

	};

	handleReferClicked = () => {
		let { payload, payloadType } = this.state;
		this.setState({ loading: true });

		const promoHeaders = {
			'Api-Version': 3,
			'x-access-token': getQuery()['hash'] || "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwib3JnSWQiOjEsIm5hbWUiOiJNYXZlcmljayAiLCJlbWFpbCI6InNhaGlsQGNsYXNzcGx1cy5jb1xuIiwibW9iaWxlIjoiOTE4NzYzMDA2MDAxIiwidHlwZSI6MywiaWF0IjoxNTg0MjE3NTk3LCJleHAiOjE1ODU5NDU1OTd9.IU0bjwNCQy5wX4YrVXKt7bYnBZ6zNn4Rj2GT472aASErAcG8F7oHEkyh6qb4WqMW"
		};

		axios
			.post(
				`${AppConfig.BASE_URL}/users/record/refral`,
				{
					contactList: payload,
					type: 1
				},
				{
					headers: promoHeaders
				}
			)
			.then((response) => {
				this.setState({ isReferClicked: true, loading: false, whatsappUrl: response.data.data.whatsAppShareLink, coinsEarned: response.data.data.coins || 0 });
			})
			.catch((error) => {
				this.setState({ loading: false });
			});
	};

	handleReferModal = () => {
		this.setState({
			isReferClicked: false,
			coinsEarned: 0,
			addSuccessModal: false,
			payload: [],
			payloadLength: 0
		});
	};



	handleInputChange = (value, type) => {
		let contactList = [...this.state.contactList];
		contactList[0][type] = value;
		if (contactList[0].mobile.length > 10) {
			this.setState({ numberLengthExceeded: true })


		}
		else if (contactList[0].name == '') {
			this.setState({ nameEmpty: true })

		}
		else {
			this.setState({
				contactList,
				numberLengthExceeded: false,
				nameEmpty: false
			})
		}

		// this.setState({
		// 		contactList,

		// 	})
	}

	handleAddNewDetails = (e) => {
		let re = /^[789]\d{9}$/
		let { contactList } = this.state;
		// this.setState({ loading: true });

		const promoHeaders = {
			'Api-Version': 3,
			'x-access-token': getQuery()['hash']
		};

		if (contactList[0].name == '' && (contactList[0].mobile.length !== 10 || !re.test(contactList[0].mobile))) {
			this.setState({ nameEmpty: true, numberLengthExceeded: true })
		}

		else if ((contactList[0].mobile.length !== 10 || !re.test(contactList[0].mobile))) {
			this.setState({ numberLengthExceeded: true })
		}
		else if (contactList[0].name == '') {
			this.setState({ nameEmpty: true })
		}
		else {

			axios
				.post(
					`${AppConfig.BASE_URL}/users/contacts`,
					{
						contactList: contactList,
						isTutorReferral: 1,

					},
					{
						headers: promoHeaders
					}
				)
				.then((response) => {

					this.setState({
						contactList: [{
							name: "",
							mobile: ""
						}],
						isAddClicked: false,
						numberLengthExceeded: false,
						nameEmpty: false,
						isReferClicked: true,
						addSuccessModal: true,
						isTutorReferral: 0,
						coinsEarned: response.data.data.coins || 0
					});
					this.handleApiCall()

				})
				.catch((error) => {

					this.setState({});
				});
		}



	}

	onChange = (item, checked) => {
		let payload = [...this.state.payload];
		let items = [...this.state.listing];

		let selectedItem = items.find((it) => {
			return it.id === item.id;
		});
		if (checked) {
			payload.push(selectedItem);
			this.setState({
				payload,
				payloadLength: payload.length
			});
			payload.length > 0 ? this.setState({ referDisabled: false }) : this.setState({ referDisabled: true })
		} else {
			payload = payload.filter((it) => {
				return it.id !== item.id;
			});
			payload.length > 0 ? this.setState({ referDisabled: false }) : this.setState({ referDisabled: true })
			this.setState({
				payload,
				payloadLength: payload.length
			});
		}
	};




	searchTermOnChange = (event) => {
		clearTimeout(this.timer)
		let searchTerm = event.target.value
		this.timer = setTimeout(() => {
			this.setState(
				{
					searchTerm,
					offset: 0
				},
				() => this.handleApiCall()
			);
		}, 500)
	};

	render() {
		const { addSuccessModal, imageKnowMoreLink, isReferClicked, isAddClicked, loading, hasMore, listing, imageUrl, payloadLength, referDisabled, numberLengthExceeded, nameEmpty, whatsappUrl, coinsEarned } = this.state;

		return (
			<div className={classes.List}>
				{loading && <Loader />}

				<div style={{ width: "100%" }} >
					<div style={{ width: '100%', height: 'auto', background: "white" }} >
						<a href={imageKnowMoreLink} className={classes.ImageLink}>
							<img src={imageUrl} alt="" style={{ width: "100%", height: "100%" }} />
							<Link to={`/terms?hash=${getQuery()['hash']}`} className={classes.TAndC} handleApiCall={this.handleApiCall}>Terms and Conditions Applied </Link>
						</a>
					</div>
					<div className={classes.Spacing} />
					<div className={classes.AddPerson} onClick={this.handleAddClicked}>
						<img src={add} className={classes.AddImage} alt="" />
						<p className={classes.AddPerson_text}>Refer a New Number by Adding</p>
					</div>
				</div>
				<div className={classes.UpperDivision}>
					<div className={classes.SelectedDivision}>
						<p className={classes.SelectedDivision_text}>Select from suggestions below</p>
						<p className={classes.SelectedDivision_text}>Selected({payloadLength})</p>
					</div>
					<div className={classes.Search}>
						<img src={search} alt="" className={classes.SearchImage} />
						<input
							type="text"
							className={classes.SearchInput}
							onKeyDown={(e) => {
								if (e.keyCode == 13) {
									e.preventDefault()
									document.activeElement.blur()
								}
							}}
							placeholder="Search"
							onChange={loading ? undefined : this.searchTermOnChange}
						/>
					</div>
				</div>

				<div className={classes.PhoneListing}>
					{listing && listing.length > 0 ? (
						<InfiniteScroll
							next={this.handleApiCall}
							hasMore={hasMore}
							dataLength={listing.length}
						>
							{listing.map((arr) => {
								return (
									<div className={classes.Phonelist} key={arr.id}>
										<label
											style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
										>
											<div className={classes.Left}>
												<p className={classes.Name}>{arr.contactName}</p>
												<p className={classes.Number}> {arr.contactNumber} </p>
											</div>
											<div className={classes.Right}>
												<Checkbox
													key={arr.id}
													checked={this.state.payload.includes(arr)}
													onChange={(checked) => {
														this.onChange(arr, checked);
													}}
												/>
											</div>
										</label>
									</div>
								);
							})}
						</InfiniteScroll>
					) : (
							!loading ? <div className={classes.ListEmpty}>No Contacts Found !</div> : ""
						)}

				</div>
				<div className={classes.ButtonDivision}>
					<button className={payloadLength > 0 ? classes.VerifyButton : classes.DisabledVerify} onClick={payloadLength ? this.handleReferClicked : ""} disabled={referDisabled}

					>
						Invite
						</button>

					<Modal toggle={this.handleReferModal} hidden={!isReferClicked}>
						<FormatModal>
							<div className={classes.ShareWhatsappModal}>
								<img src={verified} alt="" />
								<p className={classes.Success}>Success</p>
								<p className={classes.Message}>
									{addSuccessModal ?
										`Contact added, referred successfully` + (coinsEarned ? ` and you have earned ${coinsEarned} coins` : "") :
										`You have successfully referred ${payloadLength} contact(s) ` + (coinsEarned ? `and earned ${coinsEarned} coins` : "")}
								</p>
								<button className={classes.WhatsappButton}>
									<a href={`https://api.whatsapp.com/send?text=${whatsappUrl}`} target="_blank" className={classes.WhatsappAnchorTag}>
										<img src={whatsapp} alt="" />
										<p style={{ margin: '0px', paddingLeft: '4px' }}>Share on Whatsapp</p>
									</a>
								</button>
							</div>
						</FormatModal>
					</Modal>
				</div>
				<Modal toggle={this.handleAddToggle} hidden={!isAddClicked}>
					<FormatModal>
						<p className={classes.ModalTitle} style={{ paddingBottom: "12px" }}>Refer a new contact</p>

						<div className={classes.FormDivision}>
							<p className={classes.FieldHeading}>Full Name</p>
							<input type="text" className={classes.InputField} name="name" required onChange={(e) => { this.handleInputChange(e.target.value, e.target.name) }} value={this.state.contactList[0].name} />
							{nameEmpty ? <div className={classes.ErrorMessage}>
								<span>Name cannot be Empty </span>
							</div> : <div style={{ height: "10px" }}></div>}
							<p className={classes.FieldHeading}>Mobile Number</p>
							<input name="mobile" type="number" className={classes.InputField} onChange={(e) => { this.handleInputChange(e.target.value, e.target.name) }} required value={this.state.contactList[0].mobile} />
							{numberLengthExceeded ? <div className={classes.ErrorMessage}>
								<span>Enter a valid 10-digit mobile number </span>
							</div> : <div style={{ height: "10px" }}></div>}
							<button className={classes.SuccessModalOkButton} onClick={this.handleAddNewDetails} >
								<p className={classes.SuccessModalButtonText}>Invite</p>
							</button>
						</div>


					</FormatModal>
				</Modal>
			</div >
		);
	}
}

export default Phonelist;
