import React, {Component} from 'react';
import classes from "./Checkbox.module.css"
class Checkbox extends Component{
    constructor(props){
        super(props);
        this.state={
            
            
        }
    }
    render(){
       
        return(
           <div className={classes.Checkbox}>
             <input type="checkbox" checked={this.props.checked} onChange={(e)=>{
                this.props.onChange( e.target.checked)
            }} key={this.props.index}/>
           </div>
        )
    }
}

export default Checkbox