import React from 'react';
import './App.css';
import Phonelist from "./components/Phonelist/Phonelist"
import {Route, Switch } from 'react-router-dom';
import TermsAndCondition from './components/TermsAndCondition/TermsAndCondition'
function App() {
  return (
    <>
         <div className="App">
           <Switch>
                <Route path="/" component={Phonelist} exact />
                <Route path="/terms" component={TermsAndCondition} />
                
            </Switch>
         </div>

        <div className="AppLandscape">
            <p> Please go back to portrait mode for the best experience  </p>
        </div>  
    </>
  );
}

export default App;
